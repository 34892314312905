import { useState } from 'react';
import _ from 'lodash';
import { AssosciatedComtradeChannel } from '../../types/channelschart';
import AnalogChannel from '../../types/comtrade/channel/analog/analog-channel';
import DigitalChannel from '../../types/comtrade/channel/digital/digital-channel';

const useComtradeSources = <T extends AnalogChannel | DigitalChannel>(
  initialChannels: AssosciatedComtradeChannel<T>[]
) => {
  const [channels, setChannels] = useState(initialChannels);

  const addChannel = (channel: AssosciatedComtradeChannel<T>) => {
    setChannels((c) => [...c, channel]);
  };

  const removeChannel = (channel: AssosciatedComtradeChannel<T>) => {
    // if (channels.length <= 1) {
    //   return;
    // }
    setChannels((c) => c.filter((ch) => ch !== channel));
  };

  const modifyChannel = (
    channel: AssosciatedComtradeChannel<T>,
    mod: (
      channel: AssosciatedComtradeChannel<T>
    ) => AssosciatedComtradeChannel<T>
  ) => {
    // update channel
    const ch: AssosciatedComtradeChannel<T> = mod(_.cloneDeep(channel));

    setChannels((c) =>
      c.map((chan) => {
        if (chan.channel.info.idx === ch.channel.info.idx) {
          return ch;
        }
        return chan;
      })
    );

    // addChannel(ch);
  };

  return { channels, addChannel, removeChannel, modifyChannel };
};
export default useComtradeSources;

import { atom } from 'recoil';
import InteractPayload from '../../types/bus/charts/interact-payload';
import ComtradeType from '../../types/comtrade/comtrade';

export const openComtradesState = atom({
  key: 'OpenComtrades',
  default: [] as Array<ComtradeType>,
});

export const interactState = atom({
  key: 'InteractState',
  default: {
    sender: '',
    isDefault: true,
    x: {
      min: 0,
      max: 0,
    },
  } as InteractPayload,
});

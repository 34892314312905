import React, { useRef } from 'react';
import {
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { Chart as ChartJS, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Chart } from 'react-chartjs-2';
import draggableAnnotationPlugin from '../../../plugins/draggable';
import { InitialChannelsProps } from '../../../types/channelschart';
import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';
import useComtradeSources from '../../../hooks/comtradesources/comtradesource';
import useAnalogChart from '../../../hooks/charts/analog/useanalogchart';
import AnalogTransferModal from './transfer/analog-transfer-modal';

ChartJS.register(...registerables);
ChartJS.register(annotationPlugin);
ChartJS.register(draggableAnnotationPlugin);

const AnalogChannelChart = (props: InitialChannelsProps<AnalogChannel>) => {
  const rightSwitcherColor = useColorModeValue('gray.100', 'gray.700');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { initialChannels } = props;
  const { channels, addChannel, removeChannel, modifyChannel } =
    useComtradeSources<AnalogChannel>(initialChannels);

  const chartRef = useRef<any | null>(null);
  const { options, data } = useAnalogChart(chartRef, channels);

  return (
    <Flex flexDirection="row" justify="space-around" flexGrow={1}>
      <AnalogTransferModal
        isOpen={isOpen}
        onClose={onClose}
        addChannel={addChannel}
        removeChannel={removeChannel}
        modifyChannel={modifyChannel}
        channels={channels}
      />
      <Flex flexGrow={1}>
        {data && options && (
          <Chart
            type="line"
            ref={chartRef}
            style={{ position: 'absolute' }}
            options={options}
            data={data}
          />
        )}
      </Flex>
      <Flex flexDirection="column" background={rightSwitcherColor} padding={2}>
        <IconButton
          variant="ghost"
          icon={<SettingsIcon />}
          aria-label="modify sources"
          onClick={onOpen}
        />
      </Flex>
    </Flex>
  );
};

export default AnalogChannelChart;

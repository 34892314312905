import { ParseResult, parse } from 'papaparse';
import AnalogChannelInfo from '../../types/comtrade/config/analog-channel-info';
import ChannelsInfo from '../../types/comtrade/config/channels-info';
import Config from '../../types/comtrade/config/config';
import DigitalChannelInfo from '../../types/comtrade/config/digital-channel-info';
import parseAnalogChannels from './analog/analog-channels-parser';
import parseChannelsInfo from './channels-info/channels-info-parser';
import parseDigitalChannels from './digital/digital-channels-parser';

const parseConfig = (configContents: string): Config => {
  // parse from csv to json
  const jsonData: ParseResult<Array<string>> = parse(configContents);
  const { data } = jsonData;

  // get data
  const stationName: string = data[0][0];
  const recordingDeviceId: string | undefined = data[0][1];
  const revisionYear: string = data[0][2];

  // create channels info
  const channelsInfo: ChannelsInfo = parseChannelsInfo(
    data[1] as Array<string>
  );

  // get the rows of data that are analog/digital channels
  const analogChannelLines: Array<Array<any>> = data.slice(
    2,
    2 + channelsInfo.analogChannels - 1
  );
  const digitalChannelLines: Array<Array<any>> = data.slice(
    2 + channelsInfo.analogChannels,
    1 + channelsInfo.analogChannels + channelsInfo.digitalChannels
  );

  // create analog channel info
  const analogChannelInfo: Array<AnalogChannelInfo> =
    parseAnalogChannels(analogChannelLines);
  // create digital channel info
  const digitalChannelInfo: Array<DigitalChannelInfo> =
    parseDigitalChannels(digitalChannelLines);

  return {
    stationName,
    recordingDeviceId,
    revisionYear,

    channelsInfo,
    analogChannelInfo,
    digitalChannelInfo,
  };
};

export default parseConfig;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChartData, ChartDataset, ChartOptions, TooltipItem } from 'chart.js';
import { MutableRefObject, useCallback } from 'react';
import {
  useRecoilValue,
  useSetRecoilState,
  useRecoilCallback,
  useGetRecoilValueInfo_UNSTABLE,
} from 'recoil';
import { useBus, useListener } from 'react-bus';
import BusConstants from '../../../types/bus/bus-constants';
import { AssosciatedComtradeChannel } from '../../../types/channelschart';
import calculateAllDataBounds from '../../../utils/analog-channel-chart/calculators/data-calculator';
import AnalogChannelColorState from '../../../utils/analog-channel-chart/color-state';
import roundToTwoDecimalPlaces from '../../../utils/digital-channel-chart/math/rounding';
import calculateTitle from '../../../utils/analog-channel-chart/calculators/title-calculator';
import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';
import { openComtradesState, interactState } from '../../../recoil/atoms/index';
import Comtrade from '../../../types/comtrade/comtrade';
import InteractPayload from '../../../types/bus/charts/interact-payload';
import generateLineAnnotations from '../../../utils/analog-channel-chart/generators/line-annotation-generator';
import CursorMovePayload from '../../../types/bus/charts/cursor-move-payload';

const generateEmptyChartConfiguration = () => ({
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'linear',

        min: -100,
        max: 100,

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          maxTicksLimit: 10,
          maxRotation: 0, // angle in degrees

          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },

          callback: (value: any) =>
            value / 1000 < 0
              ? '0 ms'
              : `${roundToTwoDecimalPlaces(value / 1000)} ms`,
        },
      } as any,
      y: {
        type: 'linear',

        min: -100,
        max: 100,

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          color: '#cbcbcb',
          weight: 'lighter',
        },
      } as any,
    },
    plugins: {
      title: {
        display: false,
      },
    },
  },
});

const useAnalogChart = (
  chartRef: MutableRefObject<any | undefined>,
  channels: AssosciatedComtradeChannel<AnalogChannel>[]
) => {
  // create a a recoil value info 'peeker'
  const getRecoilValueInfo = useGetRecoilValueInfo_UNSTABLE();

  // get open comtrades
  const openComtrades = useRecoilValue(openComtradesState);

  // get current global scales values using a peeker that doesn't subscribe to changes
  const setInteractState = useSetRecoilState(interactState);
  const { loadable: globalInteractStateWrapper } =
    getRecoilValueInfo(interactState);
  const globalInteractState: InteractPayload =
    globalInteractStateWrapper?.contents;

  // generate line annotations using recoil hook (to prevent errors later)
  const annotations = generateLineAnnotations();

  // create chartRef reference
  const chartReference = chartRef;

  // get event bus to share zoom/pan data
  const bus = useBus();

  function dispatchInteractEvent(reference: MutableRefObject<any | undefined>) {
    // create payload
    const payload: InteractPayload = {
      sender: reference.current.id,
      x: {
        min: reference.current.scales.x.min,
        max: reference.current.scales.x.max,
      },
      isDefault: false,
    };

    bus.emit(BusConstants.CHARTS_INTERACT, payload);

    // set the global interact state (which ISN'T subscribed to in this component, so it doesn't re-render)
    setInteractState(payload);
  }

  function dispatchCursorDragEvent(
    annotation: any,
    annotationIndex: number,
    reference: MutableRefObject<any | undefined>
  ) {
    // create the payload
    const payload: CursorMovePayload = {
      sender: reference.current.id,
      annotation,
      annotationIndex,
    };

    bus.emit(BusConstants.CURSOR_MOVE, payload);
  }

  const interactCallback = useCallback((payload: InteractPayload) => {
    if (payload.sender === chartRef.current.id) {
      return;
    }
    // update chart scale/zoom/pan to be in sync with all others
    chartReference.current.options.scales.x.min = payload.x.min;
    chartReference.current.options.scales.x.max = payload.x.max;

    chartReference.current.update();
  }, []);

  const cursorCallback = useCallback((payload: CursorMovePayload) => {
    if (payload.sender === chartRef.current.id) {
      return;
    }

    // update cursor position
    const annot =
      chartReference.current.options.plugins.annotation.annotations[
        payload.annotationIndex
      ];
    annot.xMax = payload.annotation.xMax;
    annot.xMin = payload.annotation.xMin;

    chartReference.current.update();
  }, []);

  // listen for interact event
  useListener(BusConstants.CHARTS_INTERACT, (payload) =>
    interactCallback(payload)
  );

  // listen for cursor event
  useListener(BusConstants.CURSOR_MOVE, (payload) => cursorCallback(payload));

  const analogChannelColorState = new AnalogChannelColorState();

  // get labels (timestamp values)
  const labelsNumber: Array<number> =
    channels.length <= 0
      ? []
      : channels[0].channel.values.map((tv) => tv.timestamp);

  // set the global default interact state if the payload is default
  useCallback(() => {
    useRecoilCallback(
      ({ snapshot }) =>
        async () => {
          const currentInteractState = await snapshot.getPromise(interactState);
          if (currentInteractState.isDefault) {
            setInteractState({
              sender: '',
              isDefault: false,
              x: {
                min: Math.min(...labelsNumber),
                max: Math.max(...labelsNumber),
              },
            });
          }
        },
      []
    );
  }, []);

  // quick return if there's no data
  if (channels.length <= 0) {
    return generateEmptyChartConfiguration();
  }

  // convert labels to strings
  const labelsString: Array<string> = labelsNumber.map((l) => l.toString());

  const datasets: ChartDataset[] = [];

  // loop through channels and make datasets
  channels.forEach((channel) => {
    // get associated channel info from comtrade
    const { info } = channel.channel;

    // use the associated comtrade id to get the comtrade object
    const comtrade: Comtrade | undefined = openComtrades.find(
      (cm) => cm.id === channel.associatedComtradeId
    );

    // get data
    const data: Array<number> = channel.channel.values.map(
      (tv) => tv.value * (channel.channel.multiplier || 1)
    );

    const color: string = analogChannelColorState.getNextColor();

    const dataset: ChartDataset = {
      borderWidth: 1,
      pointRadius: 1,
      pointHoverRadius: 3,
      label: `${comtrade?.eventId}:${info.label}`, // label = [comtrade event id]:[label]
      data,
      borderColor: color,
      backgroundColor: color,
      cubicInterpolationMode: 'monotone',
      tension: 0.4,
    };

    datasets.push(dataset);
  });

  const allDataBounds: [number, number] = calculateAllDataBounds(datasets);
  const allDataMin: number = allDataBounds[0];
  const allDataMax: number = allDataBounds[1];

  const options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'linear',

        min: !globalInteractState.x.min
          ? Math.min(...labelsNumber)
          : globalInteractState.x.min,
        max: !globalInteractState.x.max
          ? Math.max(...labelsNumber)
          : globalInteractState.x.max,

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          maxTicksLimit: 10,
          maxRotation: 0, // angle in degrees

          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },

          callback: (value: any) =>
            value / 1000 < 0
              ? '0 ms'
              : `${roundToTwoDecimalPlaces(value / 1000)} ms`,
        },
      } as any,
      y: {
        type: 'linear',

        min: allDataMin,
        max: allDataMax,

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          color: '#cbcbcb',
          weight: 'lighter',
        },
      } as any,
    },
    plugins: {
      dragData: {
        showTooltip: true,
        enabled: true,
        onDrag(
          selectedAnnotationIndex: number,
          selectedAnnotation: any,
          chartInstance: any
        ) {
          dispatchCursorDragEvent(
            selectedAnnotation,
            selectedAnnotationIndex,
            chartRef
          );
        },
        onDragStart(
          selectedAnnotationIndex: number,
          selectedAnnotation: any,
          chartInstance: any
        ) {
          dispatchCursorDragEvent(
            selectedAnnotation,
            selectedAnnotationIndex,
            chartRef
          );
        },
      },
      title: {
        display: true,
        position: 'left',
        text: calculateTitle(channels.map((c) => c.channel)),
        color: '#cbcbcb',

        font: {
          weight: 'lighter',
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (item: TooltipItem<any>[]) => `${item[0].label} μs`,
        },
      },
      legend: {
        display: true,
      },
      zoom: {
        limits: {
          // minimum X value & maximum X value
          x: {
            min: Math.min(...labelsNumber),
            max: Math.max(...labelsNumber),
          },
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.1,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
          onZoom: () => dispatchInteractEvent(chartRef),
        },
        pan: {
          enabled: true,
          mode: 'x',

          // rangeMin: {
          //     y: allDataMin
          // },
          // rangeMax: {
          //     y: allDataMax
          // },

          onPan: () => dispatchInteractEvent(chartRef),
        } as any,
      },
      // create annotations, e.g for the 'TRIP' digital channel event
      annotation: {
        annotations,
      },
    } as any,
  };

  const data: ChartData = {
    labels: labelsString,
    datasets,
  };

  return { data, options };
};

export default useAnalogChart;

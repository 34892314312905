import React, { useRef } from 'react';
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
// import { Chart } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import useComtradeSources from '../../../hooks/comtradesources/comtradesource';
import DigitalChannel from '../../../types/comtrade/channel/digital/digital-channel';
import { InitialChannelsProps } from '../../../types/channelschart';
import useDigitalChart from '../../../hooks/charts/digital/usedigitalchart';

ChartJS.register(...registerables, zoomPlugin);

const DigitalChannelChart = (props: InitialChannelsProps<DigitalChannel>) => {
  const rightSwitcherColor = useColorModeValue('gray.100', 'gray.700');

  const { initialChannels } = props;
  const { channels } = useComtradeSources<DigitalChannel>(initialChannels);

  const chartRef = useRef<any | null>(null);

  const { options, data } = useDigitalChart(chartRef, channels);

  return (
    <Flex flexDirection="row" justify="space-between" w="100%">
      <Flex flexGrow={1}>
        {data && options && (
          <Chart
            type="bar"
            ref={chartRef}
            style={{ position: 'absolute' }}
            options={options}
            data={data}
          />
        )}
      </Flex>
      <Flex flexDirection="column" background={rightSwitcherColor} padding={2}>
        <IconButton
          variant="ghost"
          icon={<SettingsIcon />}
          aria-label="modify sources"
        />
      </Flex>
    </Flex>
  );
};

export default DigitalChannelChart;

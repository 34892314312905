import Header from '../../types/comtrade/header/header';

const parseHeader = (headerContents: string): Header => {
  // todo implement
  if (!headerContents) {
    return {};
  }
  return {};
};

export default parseHeader;

import React, { ReactElement } from 'react';
import '../../../styles/Charts.scss';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import { Flex, useColorModeValue } from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { Provider as BusProvider } from 'react-bus';
import ChannelsChart, {
  AssosciatedComtradeChannel,
} from '../../../types/channelschart';
import { openComtradesState } from '../../../recoil/atoms/index';
import AnalogChannelChart from '../../../components/charts/analog/analog-channel-chart';
import { ChannelTypeAliasType } from '../../../types/comtrade/channel/channel-type';
import DigitalChannel from '../../../types/comtrade/channel/digital/digital-channel';
import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';
import DigitalChannelChart from '../../../components/charts/digital/digital-channel-chart';
import SymmetricComponentsChart from '../../../components/charts/symmetric/symmetric-components-chart';

const channelCharts: ChannelsChart<AnalogChannel | DigitalChannel>[] = [
  {
    type: 'analog',
    initialChannels: [],
  },
  {
    type: 'analog',
    initialChannels: [],
  },
  {
    type: 'digital',
    initialChannels: [],
  },
];

const createChartPanes = () => {
  const splitterColor = useColorModeValue('#e2e8f0', '#2d3748');
  const reverseSplitterColor = useColorModeValue('#CBD5E0', '#718096');

  const openComtrades = useRecoilValue(openComtradesState);

  // todo create function that determines initial channels somehow
  // idea for analog channels:
  // first 3 channels = first 3 that are AMPS
  // second 3 channels = first 3 that are VOLTS
  function determineInitialChannels<T extends ChannelTypeAliasType>(
    type: T
  ): AssosciatedComtradeChannel<AnalogChannel | DigitalChannel>[] {
    if (type === 'digital') {
      return openComtrades.flatMap((comtrade) =>
        comtrade.digitalChannels.flatMap(
          (digitalChannel) =>
            ({
              channel: digitalChannel,
              associatedComtradeId: comtrade.id,
            } as AssosciatedComtradeChannel<DigitalChannel>)
        )
      );
    }
    if (type === 'analog') {
      return openComtrades
        .flatMap((comtrade) =>
          comtrade.analogChannels.flatMap(
            (analogChannel) =>
              ({
                channel: analogChannel,
                associatedComtradeId: comtrade.id,
              } as AssosciatedComtradeChannel<AnalogChannel>)
          )
        )
        .slice(0, 4);
    }
    if (type === 'symmetric') {
      return openComtrades
        .flatMap((comtrade) =>
          comtrade.analogChannels.flatMap(
            (analogChannel) =>
              ({
                channel: analogChannel,
                associatedComtradeId: comtrade.id,
              } as AssosciatedComtradeChannel<AnalogChannel>)
          )
        )
        .slice(0, 4);
    }
    return new Array<AssosciatedComtradeChannel<AnalogChannel>>();
  }

  // todo temporary, will be removed (just for demo)
  const createSymmectricPane = () => (
    <ReflexElement className="right-pane">
      <Flex h="100%" w="100%">
        <SymmetricComponentsChart
          initialChannels={
            determineInitialChannels<'symmetric'>(
              'symmetric'
            ) as AssosciatedComtradeChannel<AnalogChannel>[]
          }
        />
      </Flex>
    </ReflexElement>
  );

  const children: ReactElement[] = [];

  channelCharts.forEach((channelChart, idx) => {
    children.push(
      <ReflexElement
        key={String(Math.random())}
        minSize={100}
        flex={1}
        // prevents scroll bar from popping in and out of existence when quickly oscillating the reflex handle
        style={{ overflow: 'hidden' }}
      >
        <Flex h="100%" w="100%">
          {channelChart.type === 'analog' ? (
            <AnalogChannelChart
              initialChannels={
                determineInitialChannels<'analog'>(
                  'analog'
                ) as AssosciatedComtradeChannel<AnalogChannel>[]
              }
            />
          ) : (
            <ReflexContainer
              style={{
                height: 'auto',
              }}
              orientation="vertical"
            >
              <ReflexElement className="left-pane">
                <Flex w="100%" h="100%">
                  <DigitalChannelChart
                    initialChannels={
                      determineInitialChannels<'digital'>(
                        'digital'
                      ) as AssosciatedComtradeChannel<DigitalChannel>[]
                    }
                  />
                </Flex>
              </ReflexElement>
              <ReflexSplitter
                style={{
                  width: '8px',
                  backgroundColor: splitterColor,
                  borderWidth: '2px',
                  borderColor: reverseSplitterColor,
                }}
              />
              {createSymmectricPane()}
            </ReflexContainer>
          )}
        </Flex>
      </ReflexElement>
    );

    // dont render last splitter
    if (idx < channelCharts.length - 1) {
      children.push(
        <ReflexSplitter
          key={String(Math.random())}
          style={{
            height: '8px',
            backgroundColor: splitterColor,
            borderWidth: '2px',
            borderColor: reverseSplitterColor,
          }}
        />
      );
    }
  });
  return children;
};

const Charts = () => (
  <BusProvider>
    <ReflexContainer
      style={{
        height: '100%',
      }}
      orientation="horizontal"
    >
      {createChartPanes()}
    </ReflexContainer>
  </BusProvider>
);

export default Charts;

import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';

const calculateTitle = (channels: Array<AnalogChannel>): string => {
  // if all of the data channel units are the same, then use that
  // but, if they aren't the same (different units like Amps & kVolts), then make it blank

  let sameUnit: string = '';

  const allEqualUnits = channels.every((channel) => {
    const { info } = channel;

    if (info === undefined) {
      return false;
    }

    if (sameUnit === info.units || sameUnit === '') {
      sameUnit = info.units;
      return true;
    }

    return false;
  });

  return allEqualUnits ? sameUnit : '';
};

export default calculateTitle;

import React from 'react';
import { ChakraProvider, theme, Flex } from '@chakra-ui/react';
import { RecoilRoot } from 'recoil';
import Header from './components/header';
import IndexPage from './pages/index';

// react reflex initializing style
import 'react-reflex/styles.css';

const App = () => (
  <RecoilRoot>
    <ChakraProvider theme={theme}>
      <Flex flexDirection="column" h="100vh">
        <Header />
        <IndexPage />
      </Flex>
    </ChakraProvider>
  </RecoilRoot>
);

export default App;

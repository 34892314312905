import {
  Button,
  Flex,
  Heading,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { AssosciatedComtradeChannel } from '../../../../types/channelschart';
import AnalogChannel from '../../../../types/comtrade/channel/analog/analog-channel';
import { openComtradesState } from '../../../../recoil/atoms';
import Comtrade from '../../../../types/comtrade/comtrade';

interface TransferModalProps {
  isOpen: boolean;
  onClose: () => void;
  addChannel: (channel: AssosciatedComtradeChannel<AnalogChannel>) => void;
  removeChannel: (channel: AssosciatedComtradeChannel<AnalogChannel>) => void;
  modifyChannel: (
    channel: AssosciatedComtradeChannel<AnalogChannel>,
    mod: (
      channel: AssosciatedComtradeChannel<AnalogChannel>
    ) => AssosciatedComtradeChannel<AnalogChannel>
  ) => void;
  channels: AssosciatedComtradeChannel<AnalogChannel>[];
}

const AnalogTransferModal = (props: TransferModalProps) => {
  const {
    isOpen,
    onClose,
    channels,
    addChannel,
    removeChannel,
    modifyChannel,
  } = props;
  const openComtrades = useRecoilValue(openComtradesState);

  interface MultiplierState {
    comtradeId: String;
    channelId: String;
    multiplier: String;
  }

  const [sourcesMultiplierState, setSourcesMultiplierState] = useState(
    [] as MultiplierState[]
  );

  // on initialization, loop through all comtrades, add their multipliers to the state
  useEffect(() => {
    channels.forEach((channel) => {
      // if the multiplier state already exists, then don't "initilaize" it
      if (
        sourcesMultiplierState.some(
          (state) =>
            state.comtradeId === channel.associatedComtradeId &&
            state.channelId === channel.channel.info.label
        )
      ) {
        return;
      }
      setSourcesMultiplierState((multiState) => {
        multiState.push({
          comtradeId: channel.associatedComtradeId,
          channelId: channel.channel.info.label,
          multiplier: String(channel.channel.multiplier),
        });
        return multiState;
      });
    });
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent display="flex" maxW="lg">
        <ModalHeader>Modify Sources</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex gap={5} maxH="md">
            <Flex flexGrow={1} direction="column" align="center">
              <Heading size="sm">Selected Sources</Heading>
              <Flex
                direction="column"
                w="full"
                gap={2}
                padding={2}
                marginTop={2}
                overflow="auto"
              >
                {channels.flatMap((channel) => {
                  // get the associated comtrade
                  const associatedComtrade: Comtrade | undefined =
                    openComtrades.find(
                      (c) => c.id === channel.associatedComtradeId
                    );
                  return (
                    <Flex
                      direction="row"
                      align="center"
                      justify="center"
                      gap={2}
                      border={{ style: 'dashed', color: 'white' }}
                      key={`${associatedComtrade?.eventId}${channel.channel.info.label}`}
                    >
                      {associatedComtrade?.eventId}:{channel.channel.info.label}
                      <NumberInput
                        size="xs"
                        maxW={20}
                        defaultValue={1}
                        step={0.2}
                        // value={channel.channel.multiplier}
                        value={String(
                          sourcesMultiplierState.filter(
                            (m) =>
                              m.comtradeId === channel.associatedComtradeId &&
                              m.channelId === channel.channel.info.label
                          )[0]?.multiplier || 1.0
                        )}
                        onChange={(valueString: string) => {
                          if (Number.isNaN(valueString)) {
                            return;
                          }
                          modifyChannel(
                            channel,
                            (ch: AssosciatedComtradeChannel<AnalogChannel>) => {
                              // eslint-disable-next-line no-param-reassign
                              ch.channel.multiplier = Number(valueString);
                              return ch;
                            }
                          );
                          setSourcesMultiplierState((arr) => {
                            const newArr = arr.filter(
                              (m) =>
                                m.comtradeId !== channel.associatedComtradeId ||
                                m.channelId !== channel.channel.info.label
                            );
                            newArr.push({
                              comtradeId: channel.associatedComtradeId,
                              channelId: channel.channel.info.label,
                              multiplier: valueString,
                            });
                            return newArr;
                          });
                        }}
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                      <IconButton
                        icon={<MinusIcon />}
                        variant="ghost"
                        aria-label="Remove"
                        onClick={() => removeChannel(channel)}
                      />
                    </Flex>
                  );
                })}
              </Flex>
            </Flex>
            <Flex flexGrow={1} direction="column" align="center">
              <Heading size="sm">Available Sources</Heading>
              <Flex
                direction="column"
                w="full"
                gap={2}
                padding={2}
                marginTop={2}
                overflow="auto"
              >
                {openComtrades.flatMap((comtrade) =>
                  comtrade.analogChannels.map((channel) => (
                    <Button
                      key={String(Math.random())}
                      flexShrink={0}
                      isFullWidth
                      variant="ghost"
                      rightIcon={<AddIcon />}
                      justifyContent="space-between"
                      onClick={() =>
                        addChannel({
                          channel,
                          associatedComtradeId: comtrade.id,
                        })
                      }
                    >
                      {comtrade.eventId}:{channel.info.label}
                    </Button>
                  ))
                )}
              </Flex>
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AnalogTransferModal;

import React, { useRef } from 'react';
import {
  Flex,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { SettingsIcon } from '@chakra-ui/icons';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Chart } from 'react-chartjs-2';
import useSymmetricChart from '../../../hooks/charts/symmetric/usesymmetricchart';
import { InitialChannelsProps } from '../../../types/channelschart';
import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';
import useComtradeSources from '../../../hooks/comtradesources/comtradesource';
import AnalogTransferModal from '../analog/transfer/analog-transfer-modal';

ChartJS.register(...registerables);

const SymmetricComponentsChart = (
  props: InitialChannelsProps<AnalogChannel>
) => {
  const rightSwitcherColor = useColorModeValue('gray.100', 'gray.700');
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { initialChannels } = props;
  const { channels, addChannel, removeChannel, modifyChannel } =
    useComtradeSources<AnalogChannel>(initialChannels);

  const chartRef = useRef<any | null>(null);
  const { options, data } = useSymmetricChart(channels);

  return (
    <Flex flexDirection="row" justify="space-between" w="100%">
      <AnalogTransferModal
        isOpen={isOpen}
        onClose={onClose}
        addChannel={addChannel}
        removeChannel={removeChannel}
        modifyChannel={modifyChannel}
        channels={channels}
      />
      <Flex flexGrow={1}>
        {data && options && (
          <Chart
            type="scatter"
            ref={chartRef}
            style={{ position: 'absolute' }}
            options={options}
            data={data}
          />
        )}
      </Flex>
      <Flex flexDirection="column" background={rightSwitcherColor} padding={2}>
        <IconButton
          variant="ghost"
          icon={<SettingsIcon />}
          aria-label="modify sources"
          onClick={onOpen}
        />
      </Flex>
    </Flex>
  );
};

export default SymmetricComponentsChart;

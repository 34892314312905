import { AnnotationOptions } from 'chartjs-plugin-annotation';
import { useRecoilValue } from 'recoil';
import { openComtradesState } from '../../../recoil/atoms';
import DigitalChannel from '../../../types/comtrade/channel/digital/digital-channel';
import TimestampedValue from '../../../types/comtrade/channel/timestamped-value';

const generateLineAnnotations = (): AnnotationOptions[] => {
  const options: AnnotationOptions[] = [];
  // get open comtrades
  const openComtrades = useRecoilValue(openComtradesState);

  const annotationPositions: TimestampedValue[] = [];

  // loop through comtrades, getting the trip event and create annotations
  openComtrades.forEach((comtrade) => {
    comtrade.digitalChannels.forEach((digitalChannel: DigitalChannel) => {
      if (digitalChannel.info.label.toUpperCase() !== 'TRIP') {
        return;
      }

      // find where the trip event is (where it's 1, not 0)
      // then, put that location in the options as an annotation
      for (let i = 0; i < digitalChannel.values.length; i += 1) {
        const value: TimestampedValue = digitalChannel.values[i];
        if (Number(value.value) === 1) {
          // add to list of positions for annotations
          annotationPositions.push(value);
          // break from this loop
          break;
        }
      }
    });

    // create annotations for each position
    annotationPositions.forEach((position: TimestampedValue) => {
      options.push({
        type: 'line',
        xMin: position.timestamp,
        xMax: position.timestamp,
        draggable: false,
        borderColor: 'rgb(255, 99, 132)',
        borderDash: [5, 5],
        borderWidth: 2,
        label: {
          enabled: true,
          content: 'TRIP',
          position: 'start',
          padding: 2,
          borderRadius: 3,
        },
      } as any);
    });
  });

  options.push({
    type: 'line',
    xMin: 100000,
    xMax: 100000,
    draggable: true,
    borderColor: 'rgb(255, 228, 89)',
    borderWidth: 5,
    label: {
      enabled: true,
      content: 'START',
      position: 'start',
      padding: 2,
      borderRadius: 3,
    },
  } as any);

  options.push({
    type: 'line',
    xMin: 200000,
    xMax: 200000,
    draggable: true,
    borderColor: 'rgb(177, 89, 255)',
    borderWidth: 5,
    label: {
      enabled: true,
      content: 'END',
      position: 'start',
      padding: 2,
      borderRadius: 3,
    },
  } as any);

  return options;
};

export default generateLineAnnotations;

const calculateAllDataBounds = (datasets: Array<any>): [number, number] => {
  let allDataMin: number | undefined;
  let allDataMax: number | undefined;

  datasets.forEach((d) => {
    d.data
      .map((i: any) => parseInt(i, 10))
      .forEach((i: number) => {
        if (i === undefined || Number.isNaN(i)) {
          return;
        }

        if (allDataMin === undefined) {
          allDataMin = i;
        }

        if (allDataMax === undefined) {
          allDataMax = i;
        }

        allDataMin = Math.min(i, allDataMin);
        allDataMax = Math.max(i, allDataMax);
      });
  });

  allDataMin = allDataMin === undefined ? Number.MIN_SAFE_INTEGER : allDataMin;
  allDataMax = allDataMax === undefined ? Number.MAX_SAFE_INTEGER : allDataMax;

  return [allDataMin, allDataMax];
};

export default calculateAllDataBounds;

import { v4 as uuidv4 } from 'uuid';
import Comtrade from '../types/comtrade/comtrade';
import Config from '../types/comtrade/config/config';
import Header from '../types/comtrade/header/header';
import parseChannels from './channels/comtrade-channels-parser';
import parseConfig from './config/config-parser';
import parseHeader from './header/header-parser';

const parseFileContentsToComtrade = (
  configContents: string,
  headerContents: string | undefined,
  dataContents: string,
  incrementedEventId: number
): Comtrade => {
  // TODO actually parse header
  const header: Header = headerContents ? parseHeader(headerContents) : {};

  const config: Config = parseConfig(configContents);

  // parse channel
  const { analogChannels, digitalChannels } = parseChannels(
    config,
    dataContents
  );

  // generate random id
  const id = uuidv4();

  return {
    config,
    header,
    analogChannels,
    digitalChannels,
    id,
    eventId: incrementedEventId,
  };
};

export default parseFileContentsToComtrade;

import { ChartData, ChartOptions } from 'chart.js';
import { useListener } from 'react-bus';
import { useCallback, useState } from 'react';
import BusConstants from '../../../types/bus/bus-constants';
import CursorMovePayload from '../../../types/bus/charts/cursor-move-payload';
import { AssosciatedComtradeChannel } from '../../../types/channelschart/index';
import AnalogChannel from '../../../types/comtrade/channel/analog/analog-channel';
import TimestampedValue from '../../../types/comtrade/channel/timestamped-value';
import {
  calculateSymmetricComponents as calculateSymmetricComponentsPhasors,
  PhasorOutput,
} from '../../../utils/symmetric-component-chart/calculators/phasors-calculator';

const generateEmptyChartConfiguration = () => ({
  data: {
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'linear',
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },
        },
      } as any,
      y: {
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },
        },
      } as any,
    },
    plugins: {
      title: {
        display: true,
        text: 'Symmectric Components',
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  },
});

function calculateVectorCoordinates(
  xCoord: number,
  yCoord: number,
  length: number,
  angle: number
) {
  const len = typeof length !== 'undefined' ? length : 10;
  // const ang = (angle * Math.PI) / 180; // if you're using degrees instead of radians
  return {
    x: len * Math.cos(angle) + xCoord,
    y: len * Math.sin(angle) + yCoord,
  };
}

const useSymmetricChart = (
  channels: AssosciatedComtradeChannel<AnalogChannel>[]
) => {
  const [aInputValue, setAInputValue] = useState(0);
  const [bInputValue, setBInputValue] = useState(0);
  const [cInputValue, setCInputvalue] = useState(0);

  // listen for cursor move events and store the correct value index
  const cursorCallback = useCallback((payload: CursorMovePayload) => {
    if (payload.annotation.label.content !== 'START') {
      return;
    }
    // update cursor position
    const xValueFloating = Number(payload.annotation.xMax);

    const inpAVal: TimestampedValue = channels[0].channel.values.reduce(
      (prev, curr) =>
        curr.timestamp ===
        (Math.abs(curr.timestamp - xValueFloating) <
        Math.abs(prev.timestamp - xValueFloating)
          ? curr.timestamp
          : prev.timestamp)
          ? curr
          : prev
    );

    const inpBVal: TimestampedValue = channels[1].channel.values.reduce(
      (prev, curr) =>
        curr.timestamp ===
        (Math.abs(curr.timestamp - xValueFloating) <
        Math.abs(prev.timestamp - xValueFloating)
          ? curr.timestamp
          : prev.timestamp)
          ? curr
          : prev
    );

    const inpCVal: TimestampedValue = channels[2].channel.values.reduce(
      (prev, curr) =>
        curr.timestamp ===
        (Math.abs(curr.timestamp - xValueFloating) <
        Math.abs(prev.timestamp - xValueFloating)
          ? curr.timestamp
          : prev.timestamp)
          ? curr
          : prev
    );

    setAInputValue(inpAVal.value);
    setBInputValue(inpBVal.value);
    setCInputvalue(inpCVal.value);
  }, []);

  // listen for cursor event
  useListener(BusConstants.CURSOR_MOVE, (payload) => cursorCallback(payload));

  // quick return if there's no data
  if (channels.length < 3) {
    return generateEmptyChartConfiguration();
  }

  // const aInput = { angle: 0, magnitude: channels[0].channel.values[0].value };
  // const bInput = { angle: 0, magnitude: channels[1].channel.values[0].value };
  // const cInput = { angle: 0, magnitude: channels[2].channel.values[0].value };
  const aInput = { angle: 0, magnitude: aInputValue };
  const bInput = { angle: 0, magnitude: bInputValue };
  const cInput = { angle: 0, magnitude: cInputValue };

  const aOutput = calculateVectorCoordinates(
    0,
    0,
    aInput.magnitude,
    aInput.angle
  );
  const bOutput = calculateVectorCoordinates(
    0,
    0,
    bInput.magnitude,
    bInput.angle
  );
  const cOutput = calculateVectorCoordinates(
    0,
    0,
    cInput.magnitude,
    cInput.angle
  );

  const phasorOutput: PhasorOutput = calculateSymmetricComponentsPhasors({
    a: aInput,
    b: bInput,
    c: cInput,
  });

  // create chart options
  const options: ChartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'linear',
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },
        },
      } as any,
      y: {
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },
        },
      } as any,
    },
    plugins: {
      title: {
        display: true,
        text: '(PHASOR) Symmetric Components (INPUT, Pos, Neg, Zero)',
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  };

  // calculate actual phasor positions

  // POSITIVE
  const aPositive = calculateVectorCoordinates(
    0,
    0,
    phasorOutput.positive.a.magnitude(),
    phasorOutput.positive.a.angle()
  );

  const bPositive = calculateVectorCoordinates(
    0,
    0,
    phasorOutput.positive.b.magnitude(),
    phasorOutput.positive.b.angle()
  );

  const cPositive = calculateVectorCoordinates(
    0,
    0,
    phasorOutput.positive.c.magnitude(),
    phasorOutput.positive.c.angle()
  );

  // NEGATIVE

  const aNegative = calculateVectorCoordinates(
    aPositive.x,
    aPositive.y,
    phasorOutput.negative.a.magnitude(),
    phasorOutput.negative.a.angle()
  );

  const bNegative = calculateVectorCoordinates(
    bPositive.x,
    bPositive.y,
    phasorOutput.negative.b.magnitude(),
    phasorOutput.negative.b.angle()
  );

  const cNegative = calculateVectorCoordinates(
    cPositive.x,
    cPositive.y,
    phasorOutput.negative.c.magnitude(),
    phasorOutput.negative.c.angle()
  );

  // ZERO

  const aZero = calculateVectorCoordinates(
    aNegative.x,
    aNegative.y,
    phasorOutput.zero.a.magnitude(),
    phasorOutput.zero.a.angle()
  );

  const bZero = calculateVectorCoordinates(
    bNegative.x,
    bNegative.y,
    phasorOutput.zero.b.magnitude(),
    phasorOutput.zero.b.angle()
  );

  const cZero = calculateVectorCoordinates(
    cNegative.x,
    cNegative.y,
    phasorOutput.zero.c.magnitude(),
    phasorOutput.zero.c.angle()
  );

  // create datasets
  const datasets = [
    // INPUT
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, aOutput],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'darkblue',
      showLine: true,
    },
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, bOutput],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'darkred',
      showLine: true,
    },
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, cOutput],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'darkyellow',
      showLine: true,
    },

    // OUTPUT
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, aPositive, aNegative, aZero],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'blue',
      showLine: true,
    },
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, bPositive, bNegative, bZero],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'red',
      showLine: true,
    },
    {
      label: 'Scatter Dataset',
      data: [{ x: 0, y: 0 }, cPositive, cNegative, cZero],
      backgroundColor: 'rgb(255, 99, 132)',
      borderColor: 'yellow',
      showLine: true,
    },
  ];

  // create chart data to return
  // uses 'default set' of data generated
  const data: ChartData = {
    datasets,
  };

  return { data, options };
};

export default useSymmetricChart;

import { ChartData, ChartOptions } from 'chart.js';
import { MutableRefObject, useCallback } from 'react';
import { useBus, useListener } from 'react-bus';
import BusConstants from '../../../types/bus/bus-constants';
import InteractPayload from '../../../types/bus/charts/interact-payload';
import { AssosciatedComtradeChannel } from '../../../types/channelschart';
import DigitalChannel from '../../../types/comtrade/channel/digital/digital-channel';
import DigitalChannelPointRangeMap from '../../../types/comtrade/channel/digital/digital-channel-point-range-map';
import roundToTwoDecimalPlaces from '../../../utils/digital-channel-chart/math/rounding';

const generateEmptyChartConfiguration = () => ({
  data: {
    labels: [],
    datasets: [],
  },
  options: {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'linear',

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },
        },
      } as any,
      y: {
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          color: '#cbcbcb',
          weight: 'lighter',
        },
      } as any,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  },
});

const useDigitalChart = (
  chartRef: any | null,
  channels: AssosciatedComtradeChannel<DigitalChannel>[]
) => {
  // create reference to chartRef
  const chartReference = chartRef;

  // get event bus to share zoom/pan data
  const bus = useBus();

  function dispatchInteractEvent(reference: MutableRefObject<any | undefined>) {
    // create payload
    const payload: InteractPayload = {
      sender: reference.current.id,
      x: {
        min: reference.current.scales.x.min,
        max: reference.current.scales.x.max,
      },
      isDefault: false,
    };

    bus.emit(BusConstants.CHARTS_INTERACT, payload);
  }

  const interactCallback = useCallback((payload: InteractPayload) => {
    if (payload.sender === chartRef.current.id) {
      return;
    }
    // update chart scale/zoom/pan to be in sync with all others
    chartReference.current.options.scales.x.min = payload.x.min;
    chartReference.current.options.scales.x.max = payload.x.max;

    chartReference.current.update();
  }, []);

  // listen for interact event
  useListener(BusConstants.CHARTS_INTERACT, (payload) =>
    interactCallback(payload)
  );

  // quick return if there's no data
  if (channels.length <= 0) {
    return generateEmptyChartConfiguration();
  }

  // create labels (timestamp values)
  const labels: Array<string> = channels.map((ch) => ch.channel.info.label);

  // create data to calculate min & max
  const dataArray: Array<number> = channels.flatMap((ch) =>
    ch.channel.values.map((tv) => tv.timestamp)
  );

  // interpolate [ch: {timestamp, binary 0/1}, ch2: {...}] ==> [ch: [[100ms, 200ms], [300ms, 500ms]], ch2: [...]]
  const digitalChannelPointRangeMapArray: Array<
    DigitalChannelPointRangeMap<any, any>
  > = [];

  channels.forEach((ch) => {
    const pointRangeMap: Array<[any, any]> = [];
    // loop through every timestamped value & interpolate with beginning/end var
    let startTime: any = -1;
    ch.channel.values.forEach((timestampedValue) => {
      const value: number = Number(timestampedValue.value);

      if (startTime === -1) {
        if (value === 1) {
          startTime = timestampedValue.timestamp;
        }
      }

      if (startTime !== -1) {
        if (value === 0) {
          // save to range map
          pointRangeMap.push([startTime, timestampedValue.timestamp]);
          startTime = -1;
        }
      }
    });

    digitalChannelPointRangeMapArray.push({
      ranges: pointRangeMap,
    } as DigitalChannelPointRangeMap<any, any>);
  });

  // convert digital channel point range map into data array
  const digitalChannelDataArray: Array<[number, number]> = [];

  digitalChannelPointRangeMapArray
    .flatMap(
      (digitalChannelPointRangeMap) => digitalChannelPointRangeMap.ranges
    )
    .forEach((flattenedPart: [number, number]) => {
      digitalChannelDataArray.push(flattenedPart);
    });

  const minDataArray = dataArray.reduce((a, b) => Math.min(a, b));
  const maxDataArray = dataArray.reduce((a, b) => Math.max(a, b));

  // create chart options
  const options: ChartOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 0,
    },
    scales: {
      x: {
        type: 'linear',

        min: minDataArray,
        max: maxDataArray,

        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },
        ticks: {
          color: '#cbcbcb',
          font: {
            size: 12,
            weight: 'lighter',
          },

          callback: (value: any) =>
            value / 1000 < 0
              ? '0 ms'
              : `${roundToTwoDecimalPlaces(value / 1000)} ms`,
        },
      } as any,
      y: {
        grid: {
          drawBorder: true,
          width: 10,
          color: 'rgba(173,173,173,0.29)',
        },

        ticks: {
          color: '#cbcbcb',
          weight: 'lighter',
        },
      } as any,
    },
    plugins: {
      title: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          // minimum X value & maximum X value
          x: { min: minDataArray, max: maxDataArray },
        },
        zoom: {
          wheel: {
            enabled: true,
            speed: 0.03,
          },
          pinch: {
            enabled: true,
          },
          mode: 'xy',
          onZoom: () => dispatchInteractEvent(chartRef),
        },
        pan: {
          enabled: true,
          mode: 'xy',
          onPan: () => dispatchInteractEvent(chartRef),
        } as any,
      },
    },
  };

  // create a default set of data
  const defaultSet: any = {
    axis: 'y',
    data: digitalChannelDataArray,
    backgroundColor: [
      'rgba(255, 99, 132)',
      'rgba(255, 159, 64)',
      'rgba(255, 205, 86)',
      'rgba(75, 192, 192)',
      'rgba(54, 162, 235)',
      'rgba(153, 102, 255)',
      'rgba(201, 203, 207)',
    ],
  };

  // create chart data to return
  // uses 'default set' of data generated
  const data: ChartData = {
    labels,
    datasets: [defaultSet],
  };

  return { data, options };
};

export default useDigitalChart;

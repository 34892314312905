import { AddIcon, SettingsIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import useComtradeFileUpload from '../../../hooks/comtradefileupload/index';
import { openComtradesState } from '../../../recoil/atoms';
import Comtrade from '../../../types/comtrade/comtrade';

interface KeyValuePair {
  key: string;
  value: string;
}
interface Event {
  title: string;
  descriptionLines: KeyValuePair[];
}

const Events = () => {
  const [openComtrades, setOpenComtrades] = useRecoilState(openComtradesState);
  const [eventData, setEventData] = useState<Event[]>([]);

  const acceptNewComtrade = (comtrade: Comtrade) => {
    if (!comtrade) {
      return;
    }

    // add comtrade file to recoil
    setOpenComtrades((open) => [...open, comtrade]);
  };

  const { openFileDialog, isLoading } =
    useComtradeFileUpload(acceptNewComtrade);

  useEffect(() => {
    // get all comtrades and create event data
    const data: Event[] = [];

    openComtrades.forEach((comtrade) => {
      // create description lines
      const descriptionLines: KeyValuePair[] = [
        {
          key: 'Event Id',
          value: String(comtrade.eventId),
        },
        {
          key: 'Analog Channels',
          value: String(comtrade.config.channelsInfo.analogChannels),
        },
        {
          key: 'Digital Channels',
          value: String(comtrade.config.channelsInfo.digitalChannels),
        },
        {
          key: 'Recording Device Id',
          value: String(comtrade.config.recordingDeviceId),
        },
        {
          key: 'Revision Year',
          value: String(comtrade.config.revisionYear),
        },
      ];

      data.push({
        title: comtrade.config.stationName,
        descriptionLines,
      });
    });

    // set event data
    setEventData(data);
  }, [openComtrades]);

  return (
    <Flex flexGrow={1} flexDirection="column">
      <Stack direction="row" spacing={4} padding={4}>
        <Button
          rightIcon={<AddIcon />}
          colorScheme="green"
          variant="outline"
          onClick={openFileDialog}
          isLoading={isLoading}
        >
          Add Event
        </Button>
      </Stack>
      <Accordion allowToggle flexGrow={1}>
        {eventData.map((element) => (
          <AccordionItem key={String(Math.random)}>
            <Flex gap={2}>
              <AccordionButton gap={3}>
                <Box flex="1" textAlign="left">
                  {element.title}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <IconButton
                variant="ghost"
                icon={<SettingsIcon />}
                aria-label="open event settings"
              />
            </Flex>
            <AccordionPanel pb={4}>
              {element.descriptionLines.map((line) => (
                <Flex
                  key={String(Math.random())}
                  flexDirection="row"
                  align="center"
                  justify="start"
                  wrap="wrap"
                  gap={2}
                >
                  <Text>{line.key}:</Text>
                  <Badge>{line.value}</Badge>
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};
export default Events;

import { Flex, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import '../../styles/IndexPage.scss';
import { ReflexContainer, ReflexElement, ReflexSplitter } from 'react-reflex';
import Events from './events';
import Charts from './charts';
// import Charts from './charts';
// import Events from './events';

const IndexPage = () => {
  const splitterColor = useColorModeValue('#e2e8f0', '#2d3748');
  const reverseSplitterColor = useColorModeValue('#CBD5E0', '#718096');

  return (
    <Flex overflow="hidden" height="100%">
      <ReflexContainer
        style={{
          height: 'auto',
        }}
        orientation="vertical"
      >
        <ReflexElement flex={0.7} minSize={600} className="left-pane">
          <Charts />
        </ReflexElement>
        <ReflexSplitter
          style={{
            width: '8px',
            backgroundColor: splitterColor,
            borderWidth: '2px',
            borderColor: reverseSplitterColor,
          }}
        />
        <ReflexElement flex={0.3} minSize={200} className="right-pane">
          <Events />
        </ReflexElement>
      </ReflexContainer>
    </Flex>
  );
};
export default IndexPage;

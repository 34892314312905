import Victor from 'victor';

export interface CursorPayload {
  a: {
    angle: number;
    magnitude: number;
  };
  b: {
    angle: number;
    magnitude: number;
  };
  c: {
    angle: number;
    magnitude: number;
  };
}

export interface PhasorOutput {
  positive: {
    a: Victor;
    b: Victor;
    c: Victor;
  };
  negative: {
    a: Victor;
    b: Victor;
    c: Victor;
  };
  zero: {
    a: Victor;
    b: Victor;
    c: Victor;
  };
}

function toRadians(angle: number) {
  return angle * (Math.PI / 180);
}

export const calculateSymmetricComponents = (
  cursorPayload: CursorPayload
): PhasorOutput => {
  const { a, b, c } = cursorPayload;

  const inputABottomSide = a.magnitude * Math.cos(toRadians(a.angle));
  const inputAOppositeSide = a.magnitude * Math.sin(toRadians(a.angle));

  const inputBBottomSide = b.magnitude * Math.cos(toRadians(b.angle));
  const inputBOppositeSide = b.magnitude * Math.sin(toRadians(b.angle));

  const inputCBottomSide = c.magnitude * Math.cos(toRadians(c.angle));
  const inputCOppositeSide = c.magnitude * Math.sin(toRadians(c.angle));

  const inputAVector = new Victor(inputABottomSide, inputAOppositeSide);
  const inputBVector = new Victor(inputBBottomSide, inputBOppositeSide);
  const inputCVector = new Victor(inputCBottomSide, inputCOppositeSide);

  // A PHASORS
  const aPositive = inputAVector
    .clone()
    .add(inputBVector.clone().rotateDeg(120))
    .add(inputCVector.clone().rotateDeg(240))
    .divide(new Victor(3, 3));

  const aNegative = inputAVector
    .clone()
    .add(inputBVector.clone().rotateDeg(240))
    .add(inputCVector.clone().rotateDeg(120))
    .divide(new Victor(3, 3));

  const aZero = inputAVector
    .clone()
    .add(inputBVector.clone())
    .add(inputCVector.clone())
    .divide(new Victor(3, 3));

  // B PHASORS
  const bPositive = aPositive.clone().rotateDeg(-120);
  const bNegative = aNegative.clone().rotateDeg(120);
  const bZero = aZero.clone();

  // C PHASORS
  const cPositive = aPositive.clone().rotateDeg(120);
  const cNegative = aNegative.clone().rotateDeg(-120);
  const cZero = aZero.clone();

  return {
    positive: {
      a: aPositive,
      b: bPositive,
      c: cPositive,
    },
    negative: {
      a: aNegative,
      b: bNegative,
      c: cNegative,
    },
    zero: {
      a: aZero,
      b: bZero,
      c: cZero,
    },
  };
};

import {
  Flex,
  Text,
  IconButton,
  useColorMode,
  useColorModeValue,
  Avatar,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import React from 'react';
import iconImage from '../../assets/icon.png';

const Header = () => {
  const icon = useColorModeValue(<SunIcon />, <MoonIcon />);
  const background = useColorModeValue('gray.200', 'gray.700');
  const { toggleColorMode } = useColorMode();

  return (
    <Flex
      flexDirection="row"
      minW="full"
      align="center"
      justify="space-between"
      gap={5}
      padding={2}
      background={background}
    >
      <Flex align="center" gap={5}>
        <Avatar size="sm" src={iconImage} background="black" />
        <Text>Tarigma Oscillography Viewer</Text>
      </Flex>
      <IconButton
        icon={icon}
        aria-label="toggle color mode"
        onClick={toggleColorMode}
      />
    </Flex>
  );
};

export default Header;
